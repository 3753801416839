import { render, staticRenderFns } from "./JResourceItem.vue?vue&type=template&id=086de3de&scoped=true&"
import script from "./JResourceItem.vue?vue&type=script&lang=js&"
export * from "./JResourceItem.vue?vue&type=script&lang=js&"
import style0 from "./JResourceItem.vue?vue&type=style&index=0&id=086de3de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../ware/node-v14.18.1-win-x64/node_modules/@vue/cli-service-global/node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086de3de",
  null
  
)

export default component.exports